import { Address, Color, User } from 'types/user.type';
import { ShippingLeadTime } from 'types/shipping.type';
import { Size } from 'types/product.type';
import { GiftCardRecord } from 'types/gift-card.type';

export interface Order {
  id: number;
  type: string;
  usingShippo: boolean;
  address: Address;
  billingAddress: Address;
  customerName: string;
  completedAt: string;
  completedAtSec: number;
  completedDateSec?: string;
  shipsAt: string;
  payment: OrderPayment;
  stocks: Stock[];
  labelUrls: string;
  status: string;
  completedJobAmount: number;
  amount: PaymentAmount;
  createdAt: string;
  createdAtSec: number;
  notes: string;
  productIds: string;
  canCompleteRefund: boolean;
  readyToShip: boolean;
  readyToShipId: string;
  transactionId: number;
  transactionEmailLog: string;
  inProgress: boolean;
  shipping: Shipping;
  statuses: Status[];
  shipments: Shipment[];
  giftCards: GiftCardRecord[];
}

export interface PaymentAmount {
  shipping: number;
  tax: number;
  subtotal: number;
  total: number;
  credit: number;
  gift: number;
}

export interface OrderPayment {
  applePay: boolean;
  applePayToken: string;
  number: string;
  cardType: string;
  billingZipCode: string;
  expiration: string;
  chargeId: string;
  type: string;
}

export enum ShipmentStatus {
  Waiting = 'Waiting',
  Completed = 'Completed',
  InStock = 'In stock',
  ReturnInProgress = 'Return in progress',
  Refunded = 'Refunded',
  Cancelled = 'Cancelled',
  Shipped = 'Shipped',
  ReturnCancelled = 'Return canceled',
  Delivered = 'Delivered',
}

export enum StockAssociationStatus {
  Waiting = 'Waiting',
  Completed = 'Completed',
  InStock = 'In stock',
  ReturnInProgress = 'Return in progress',
  Refunded = 'Refunded',
  Cancelled = 'Cancelled',
  Shipped = 'Shipped',
  ReturnCanceled = 'Return canceled',
}

export interface Shipment {
  id: number;
  status: ShipmentStatus;
  estimateDate: ShippingLeadTime;
  shipmentHistory: string;
  rates: string;
  trackingNumber: string;
  e6SentAt: string;
  labelUrls: string[];
  shippingOptions: ShippingOptions;
  shipmentEmailSent: boolean;
  statusUpdateDate: Date;
  shippoTransactionIds: any[];
  rateCost: string;
  signatureRequired: boolean;
  createdAt: Date;
  updatedAt: Date;
  transactionId: number;
  stocks: Stock[];
  canCancel: boolean;
  isSignatureRequired: boolean;
}

export interface ShippingOptions {
  provider: string;
  type: string;
  cost: string;
}

export interface Stock {
  id: number;
  completedJobPercent: number;
  beingMade: boolean;
  productId: number;
  stockInfoId: number;
  type: string;
  shipmentId: number;
  product: Product;
  productOptions: ProductOptions;
  amount: StockAmount;
  payoutId: string;
  status: StockAssociationStatus;
  userId: number;
  transactionId: number;
  jobs: any[];
  refundReason: string;
  replacementReason: string;
  saleDate: Date;
  isGift?: boolean;
  giftMessage?: string;
}

export interface StockAmount {
  subtotal: number;
  tax: number;
  manufacturerPrice: number;
  transactionFee: number;
}

export interface Product {
  id: number;
  name: string;
  fullName: string;
  fullUrlName: string;
  fullSkuName: string;
  designer: string;
  sku: string;
  amount: StockAmount;
  size: Size;
  width: number;
  depth: number;
  height: number;
  color: Color;
  variant: string;
  category: string;
  imageUrl: string;
}

export interface ProductOptions {
  colors: Color[];
  sizes: Size[];
  variants: any[];
}

export interface Shipping {
  leadTimeSec: string;
  status: string;
  statusDate: string;
  statusDateSec: string;
  tracking: string;
  leadTime: string;
  provider: string;
  trackingHistory: string;
  estimatedShipment: string;
  estimatedShipmentExpired: boolean;
  estimatedShipmentSec: number;
}

export interface Status {
  id: number;
  ownerId: number;
  ownerType: string;
  orderId?: number;
  meta?: string;
  status?: string;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
  userId?: number;
  user?: User;
  createdAtSec: string;
}
