import styled, { css } from 'styled-components';
import { media } from 'styles/media';
import { GiftCardsBlockProps } from 'modules/shop/cart/sections/gift-cards-block/gift-cards-block.props';

export const StyledCartSection = styled.div<Partial<GiftCardsBlockProps>>`
  ${({ padded, theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius};
    padding: ${padded ? theme.spacing.s3 : 'unset'};

    ${media.lessThan('md')`
      padding: ${padded ? theme.spacing.s2 : 'unset'};
    `}
  `}
`;

export const StyledCheckoutButtonContainer = styled.div<{
  marginTop?: string;
  marginBottom?: string;
}>`
  ${({ theme, marginTop, marginBottom }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    column-gap: ${theme.spacing.s1};
    margin-top: ${marginTop || theme.spacing.s4};
    margin-bottom: ${marginBottom || theme.spacing.s3};
    z-index: 2;
    pointer-events: none;

    & > * {
      min-width: auto;
    }

    ${media.lessThan('md')`
       position: fixed;
       bottom: 0;
       left: 0;
       width: 100%;
       background: linear-gradient(180deg,rgba(245, 245, 245, 0) ,#f4f4f4 80px);
       margin: unset;
       padding: 8rem ${theme.spacing.s3} ${theme.spacing.s3};
    `};
  `}
`;
