import styled, { css } from 'styled-components';
import { media } from 'styles/media';
import { Icon } from 'core/icon/icon';

export const StyledGiftCardRow = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 8rem 1fr;
    gap: ${theme.spacing.s2};
    user-select: none;
    align-items: start;

    ${media.lessThan('sm')`
      grid-template-columns: 6rem 1fr;
      align-items: start;
    `}
  `}
`;

export const StyledSpecsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledRightAlignedBlock = styled.div`
  text-align: right;
  position: relative;

  ${media.lessThan('sm')`
    display: none;
  `}
`;

export const StyledCloseButton = styled(Icon)`
  position: relative;
  cursor: pointer;
  top: 6px;
`;

export const StyledRowContent = styled.div<{ removable: boolean }>`
  ${({ removable, theme }) => css`
    display: grid;
    height: max-content;
    grid-template-columns: 1fr max-content ${removable ? 'max-content' : ''};
    column-gap: ${theme.spacing.s1};
  `}
`;

export const StyledCard = styled.div`
  ${({ theme }) => css`
    box-shadow: ${theme.shadow};
    border-radius: 0.6rem;
    overflow: hidden;
    position: relative;
  `};
`;
