import React from 'react';
import {
  StyledStep,
  StyledStepsContainer,
  StyledStepsContent,
} from 'core/steps/steps.styles';
import { StepsProps } from 'core/steps/steps.props';
import { StepsPresets } from 'core/steps/steps.presets';
import { useSprings } from 'react-spring';
import useResizeObserver from 'use-resize-observer/polyfilled';

export const Steps = (props: StepsProps) => {
  const { steps, currentStep, onStepChange } = Object.assign(
    {},
    StepsPresets,
    props,
  );

  const { width: containerWidth, ref: measureRef } = useResizeObserver();

  const [animationProps] = useSprings(
    steps.length,
    index => {
      const active = index === currentStep;

      return {
        zIndex: active ? 1 : -1,
        opacity: 1,
        config: { duration: 100 },
      };
    },
    [containerWidth, currentStep],
  );

  return (
    <StyledStepsContainer ref={measureRef as any}>
      <StyledStepsContent>
        {animationProps.map(({ zIndex, opacity }, i: number) => (
          <StyledStep
            key={i}
            style={{
              zIndex,
              opacity,
              position: i === currentStep ? 'unset' : 'absolute',
            }}>
            {i === currentStep ? steps[i].content : null}
          </StyledStep>
        ))}
      </StyledStepsContent>
    </StyledStepsContainer>
  );
};
