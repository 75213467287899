import styled from 'styled-components';
import { animated } from '@react-spring/web';

export const StyledStepsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

export const StyledStepsContent = styled.ul`
  display: inline-flex;
  width: 100%;
`;

export const StyledStep = styled(animated.li)`
  list-style: none;
  width: 100%;
  min-width: 100%;
`;
