import { api } from 'services/api';
import { CartRecord, useCart } from 'context/cart.context';
import { useAuth } from 'context/auth.context';
import { useNotification } from 'context/notification.context';
import { CartProduct } from 'services/api/cart/cart-api.types';
import {
  convertSkuToCartProduct,
  extractAnalyticsProductDataFromSku,
} from 'modules/shop/product/product.adapter';
import { SKU } from 'types/product.type';
import { useRouter } from 'next/router';
import { AUTH_TOKEN, CART_ITEMS } from 'constants/common';
import { getFromLocalStorage, saveToLocalStorage } from 'utils/storage';
import { GiftCardRecord } from 'types/gift-card.type';
import { useCookies } from 'react-cookie';
import { trackAnalyticsEvent } from 'utils/analytics';
import { useModal } from 'context/modal.context';
import { OrderLimitReachedModal } from '../modals';
import React from 'react';

export const useGiftCode = () => {
  const [, cartDispatch] = useCart();
  const [{ user }] = useAuth();
  const { notify } = useNotification();

  const applyGiftCode = async (value: string) => {
    if (value) {
      const { data, success, error } = await api.user.verifyGiftCode({
        giftCode: value,
        email: user?.email,
        userId: user?.id,
      });

      if (success) {
        cartDispatch({
          type: 'SET_GIFT_CODE',
          code: data.gift,
        });

        notify({
          message: data.notice,
          isError: false,
        });
      } else {
        notify({
          message: error.message,
          isError: true,
        });
      }
    } else {
      cartDispatch({
        type: 'SET_GIFT_CODE',
        code: null,
      });
    }
  };

  return { applyGiftCode };
};

export const useCartHelpers = () => {
  const [{ records }, cartDispatch] = useCart();
  const [{ authenticated }] = useAuth();
  const [cookies] = useCookies([AUTH_TOKEN]);
  const router = useRouter();
  const { notifyError } = useNotification();

  const { showModal: showOrderLimitReachedModal } = useModal({
    template: <OrderLimitReachedModal />,
    width: '46rem',
  });

  const addToCart = async (record: CartRecord) => {
    let temporal: CartRecord[];

    const cartItems: CartRecord[] = getFromLocalStorage(CART_ITEMS) || [];

    temporal = [...(cartItems || []), record];

    cartDispatch({
      type: 'SET_PROPERTIES',
      data: {
        records: temporal,
      },
    });

    if (cookies[AUTH_TOKEN]) {
      cartDispatch({ type: 'SET_STATUS', value: 'SYNCING' });

      const {
        data: { success, data },
      } = await api.cart.update({ cart: temporal });

      if (success) {
        cartDispatch({ type: 'SET_STATUS', value: 'SYNCED' });
        temporal = data.cart;

        cartDispatch({
          type: 'SET_PROPERTIES',
          data: {
            records: temporal,
          },
        });
      } else {
        cartDispatch({ type: 'SET_STATUS', value: 'SYNC_FAILED' });
        notifyError('An error occurred adding product to cart.');
      }
    }

    saveToLocalStorage(CART_ITEMS, temporal);

    await router.push('/cart');
  };

  const addSkuToCart = async (product: SKU) => {
    const cartItems: CartRecord[] = getFromLocalStorage(CART_ITEMS) || [];

    if (cartItems?.length >= 20) {
      showOrderLimitReachedModal();
      return;
    }

    const sameSkusInStockInCart = cartItems.filter(
      (item: CartProduct) => item.sku === product.sku && item.inStock,
    );

    const inStock = product.stocks > sameSkusInStockInCart.length;

    await addToCart({
      ...convertSkuToCartProduct(product, inStock),
      type: 'product',
    });
  };

  const addGiftCardToCart = async (record: GiftCardRecord) => {
    await addToCart({
      ...record,
      type: 'gift-card',
    });
  };

  const updateCart = async (
    records: CartRecord[],
    analyticsData: any,
    trackRemove: boolean = true,
  ) => {
    cartDispatch({
      type: 'SET_PROPERTIES',
      data: {
        records,
      },
    });

    if (authenticated) {
      const {
        data: { success },
      } = await api.cart.update({ cart: records });

      if (!success) {
        notifyError('An error occurred updating cart.');
      }
    }

    if (trackRemove) {
      trackAnalyticsEvent(
        'Product Removed',
        extractAnalyticsProductDataFromSku(analyticsData),
      );
    }

    saveToLocalStorage(CART_ITEMS, records);
  };

  const toggleIsGift = async (value: boolean, cartId: string) => {
    let temporal = [...records];

    const index = temporal.findIndex(item => item.cartId === cartId);

    if (index > -1) {
      temporal[index] = Object.assign({}, temporal[index], {
        isGift: value,
        giftMessage: '',
      });

      await updateCart(
        temporal,
        extractAnalyticsProductDataFromSku(temporal[index] as any),
      );
    }
  };

  const updateCartRecordGiftMessage = async (value: string, cartId: string) => {
    let temporal = [...records];

    const index = temporal.findIndex(item => item.cartId === cartId);

    if (index > -1) {
      temporal[index] = Object.assign({}, temporal[index], {
        giftMessage: value || '',
      });

      await updateCart(
        temporal,
        extractAnalyticsProductDataFromSku(temporal[index] as any),
      );
    }
  };

  return {
    addSkuToCart,
    addGiftCardToCart,
    updateCart,
    toggleIsGift,
    updateCartRecordGiftMessage,
  };
};
