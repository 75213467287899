import styled, { css } from 'styled-components';

export const StyledCheckboxContainer = styled.div<{ disabled: boolean }>`
  ${({ disabled }) => css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    pointer-events: ${disabled ? 'none' : 'unset'};
    opacity: ${disabled ? 0.5 : 1};
    user-select: none;
  `}
`;

export const StyledCheckbox = styled.div`
  ${({ theme }) => css`
    width: 2rem;
    height: 2rem;
    border-radius: 0.3rem;
    border: 1px solid ${theme.colors.lightGrey};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.white};
  `}
`;
