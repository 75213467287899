import React from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { Icon } from 'core/icon';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { Spacer } from 'layout/spacer';
import { useModalClose } from 'context/modal.context';

export const OrderLimitReachedModal = () => {
  const { closeLastOpened } = useModalClose();

  return (
    <StackedContainer
      verticalPadding={{ lg: '10rem', md: 's5', sm: 's4' }}
      horizontalPadding={{ lg: 's5', sm: 's2' }}
      justifyContent="center"
      justifyItems="center">
      <Icon name="cart" color="primary" size="4rem" />

      <Typography
        tx="cart:limitReachedModal.title"
        variant="h4"
        align="center"
      />

      <Typography
        tx="cart:limitReachedModal.description"
        variant="p3"
        color="grey"
        align="center"
      />

      <Spacer height="s2" />

      <StackedContainer width="100%" padding="unset">
        <Button
          tx="cart:limitReachedModal.contactUsBtn"
          linkHref="https://support.gantri.com/hc/en-us"
          linkPrefetch={false}
          linkTarget="_blank"
          width="100%"
          onClick={closeLastOpened}
        />
      </StackedContainer>
    </StackedContainer>
  );
};
